.outer{
    text-align: center;
}

.inner{
    border: 1px solid #CCCCCC;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    width: 160px;
    height: 30px;

    display: inline-block;
}

.dummy{
    width: 160px;
    height: 30px;

    display: inline-block;
}

@media only screen and (max-width: 800px) {
    .outer {
        display: table-cell;
        vertical-align: middle;
    }

    .inner {
        width: 100px;
        height: 47px;
    }
    
    .dummy{
        width: 100px;
        height: 47px;
    }
}

.me{
    background-color: #938f8f;
    color: #fff;
}

.possible_to_forder{
    background-color: #9ff38d;
    color: #084f02;
    cursor: pointer;
}