
@media print {
    @page {
        size: auto !important;
    }
    body{
        font-size: 20px !important;
    }

    .inner{
        font-size: 18px;
       border: 2px solid #CCCCCC !important;
        margin: 2px;
        padding-top: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        width: 150px;
        height: 46px;

        display: inline-block;
    }

    .dummy{
        width: 150px;
        height: 46px;
    }
    
    .possible_to_forder{
        color: #000000;
    }

    .print_hide{
        display: none;
    }
    
    .btn{
        display: none;
    }
    
    .print-margin-top{
        margin-top: 20px;
    }
}