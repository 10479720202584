.App {
}


body{
  background-color: #F0F2F5 !important;
  position: absolute;
}

.busy-cursor{
  cursor:wait;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-container {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.content-container {
  max-width: 720px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}


.myLargeBtn{
  width: 150px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-form-explain{
  color: red;
}